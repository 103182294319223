import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable, of } from "rxjs";
import { map, catchError, tap } from "rxjs/operators";
import { HttpClient } from "@angular/common/http";
import { Constants } from "../utility/constants";
import { environment } from "../../environments/environment";
import { Router } from "@angular/router";

@Injectable({
  providedIn: "root",
})
export class AdminService {
  isAuthenticated: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    null
  );
  constructor(
    private http: HttpClient,
    private constantUrl: Constants,
    private route: Router
  ) {
    this.loadToken();
  }

  /**
   * @package Services
   * @method extractData
   * @description A method to return data from the response received
   * @param res JSON/ String response received from the requesting service
   * @returns A JSON array with requested data
   */
  public extractData(res: Response): any {
    const body = res;
    return body || {};
  }

  /**
   * @package Services
   * @method handleError
   * @description A method to return human readable errors
   * @param operation service method that requested a resource
   * @param result usually an error response from a server
   * @returns An observable of error response
   */
  public handleError<T>(operation = "operation", result?: T) {
    return (error: any): Observable<T> => {
      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead

      // TODO: better job of transforming error for user consumption

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }
  Registration(Details) {
    return this.http
      .post(this.constantUrl.registerURL + "Register", Details)
      .map((res) => res);
  }
  UpdateUser(Details) {
    return this.http
      .post(this.constantUrl.registerURL + "UpdateUser", Details)
      .map((res) => {
        res;
      });
  }
  getAgentData() {
    return this.http
      .get(this.constantUrl.agentUrl)
      .pipe(
        map(this.extractData),
        // catchError(this.handleError<any>("getAgentData"))
      );
  }
  GetAnyReportList(take, skip) {
    return this.http
      .get(
        environment.apiBaseUrl +
          "AnyReport/GetAnyReportList?" +
          "&skip=" +
          take +
          "&take=" +
          skip
      )
      .pipe(
        map(this.extractData),
        // catchError(this.handleError<any>("getAgentData"))
      );
  }
  getAppUsersById(UserTypeId, take, skip) {
    return this.http
      .get(
        this.constantUrl.getAppUsersById +
          UserTypeId +
          "&skip=" +
          take +
          "&take=" +
          skip
      )
      .pipe(
        map(this.extractData),
        // catchError(this.handleError<any>("getAgentData"))
      );
  }
  updateUser(object) {
    return this.http
      .post(this.constantUrl.updateUser, object)
      .pipe(
        map(this.extractData),
        // catchError(this.handleError<any>("getAgentData"))
      );
  }
  removeUser(Id) {
    return this.http
      .post(this.constantUrl.removeUser + Id, {})
      .pipe(
        map(this.extractData),
        // catchError(this.handleError<any>("getAgentData"))
      );
  }
  SaveAgent(Details) {
    return this.http
      .post(environment.apiBaseUrl + "Agent/SaveAgent", Details)
      .map((res) => res);
  }
  UpdateAgent(Details) {
    return this.http
      .post(environment.apiBaseUrl + "Agent/UpdateAgent", Details)
      .map((res) => res);
  }
  removeAgent(Id) {
    return this.http
      .post(environment.apiBaseUrl+"Agent/RemoveAgent?Id=" + Id, {})
      .pipe(
        map(this.extractData),
        // catchError(this.handleError<any>("getAgentData"))
      );
  }
  GetAgentDataList(take, skip) {
    return this.http
      .get(
        environment.apiBaseUrl +
          "Agent/GetAgentList?" +
          "&skip=" +
          take +
          "&take=" +
          skip
      )
      .pipe(
        map(this.extractData),
        // catchError(this.handleError<any>("getAgentData"))
      );
  }
  GetAgentDataListFilter(take, skip, mobileNo) {
    return this.http
      .get(
        environment.apiBaseUrl +
          "Agent/GetAgentList?" +
          "&skip=" +
          take +
          "&take=" +
          skip+"&mobileNo="+mobileNo
      )
      .pipe(
        map(this.extractData),
        // catchError(this.handleError<any>("getAgentData"))
      );
  }

  getAdminDetails(Id) {
    return this.http
      .get(environment.apiBaseUrl+"Agent/GetAgent?Id=" + Id)
      .pipe(
        map(this.extractData),
        // catchError(this.handleError<any>("getAgentData"))
      );
  }
  ImportAgentListFromExcel(details) {
    return this.http
      .post(
        environment.apiBaseUrl +
          "Agent/ImportAgentFromExcel",
        details
      )
      .pipe(
        map(this.extractData),
        // catchError(this.handleError<any>("getAgentData"))
      );
  }
  loadToken() {
    const tokenValue = JSON.parse(localStorage.getItem("userDetails"));
    if (tokenValue) {
      this.isAuthenticated.next(true);
    } else {
      this.isAuthenticated.next(false);
      this.route.navigateByUrl("");
    }
  }
}
