import { Component } from "@angular/core";
import { FileUploader, FileLikeObject } from "ng2-file-upload";

@Component({
  selector: "app-multi-image-upload",
  templateUrl: "./multi-image-upload.component.html",
  styleUrls: ["./multi-image-upload.component.scss"]
})
export class MultiImageUploadComponent {
  public uploader: FileUploader = new FileUploader({
    url:'',
    allowedFileType: ["image"],
    queueLimit:4,
    disableMultipart: true, // 'DisableMultipart' must be 'true' for formatDataFunction to be called.
    formatDataFunctionIsAsync: true,
    formatDataFunction: async (item) => {
      return new Promise( (resolve, reject) => {
        resolve({
          name: item._file.name,
          length: item._file.size,
          contentType: item._file.type,
          date: new Date()
        });
      });
    }
    
  });

  constructor() {}

  getFiles(): FileLikeObject[] {
    return this.uploader.queue.map(fileItem => {
      return fileItem.file;
    });
  }

  reorderFiles(reorderEvent: CustomEvent): void {
    
    let element = this.uploader.queue.splice(reorderEvent.detail.from, 1)[0];
    this.uploader.queue.splice(reorderEvent.detail.to, 0, element);
   
  }}

