// import { NbToastrService } from '@nebular/theme';
import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { LoaderComponent } from "./components/loader/loader.component";
import { MaterialModule } from "../../material.module";
import { FileUploadModule } from "ng2-file-upload";
import { MultiImageUploadComponent } from "./components/multi-file-upload copy/multi-image-upload.component";
import { SocietyFilterPipe } from "../../utility/society-filter.pipe";
import { ImportExportPropertyComponent } from "./components/import-export-property/import-export-property.component";
import { DataLoaderComponent } from "./components/data-loader/data-loader.component";
import { NgxSpinnerModule } from "ngx-spinner";
import { AreaFilterPipe } from "../../utility/area-filter.pipe";


@NgModule({
  declarations: [
    LoaderComponent,
    MultiImageUploadComponent,
    SocietyFilterPipe,
    AreaFilterPipe,
    ImportExportPropertyComponent,
    DataLoaderComponent
  ],
  imports: [
    CommonModule,
    MaterialModule,
    FileUploadModule,
    NgxSpinnerModule
    // NbToastrService
  ],
  exports: [
    // LoaderComponent,
    MultiImageUploadComponent,
    SocietyFilterPipe,
    AreaFilterPipe,
    ImportExportPropertyComponent,
    DataLoaderComponent,
    NgxSpinnerModule
  ],
})
export class SharedModule {}
