<div class="header-container">
  <div class="logo-container">
    <a (click)="toggleSidebar()" href="#" class="sidebar-toggle">
      <nb-icon icon="menu-2-outline"></nb-icon>
    </a>
    <a class="logo" (click)="navigateHome()">CloRent <span>Factory</span></a>
  </div>
  <nb-select style="margin-left:25%;" [selected]="currentTheme" (selectedChange)="changeTheme($event)" status="primary">
    <nb-option *ngFor="let theme of themes" [value]="theme.value"> {{ theme.name }}</nb-option>
  </nb-select>
</div>

<div class="header-container">
  <nb-actions size="small">

    <nb-action class="control-item">
      <nb-search type="rotate-layout"></nb-search>
    </nb-action>
    <nb-action class="control-item" icon="email-outline"></nb-action>
    <nb-action class="control-item" icon="bell-outline"></nb-action>
 
    <nb-action class="user-action"   >
      <nb-user  [nbContextMenu]="userMenu"  [onlyPicture]="userPictureOnly"
        [name]="userDetails.FName" picture="assets/icons/appicon.png">
      </nb-user>
    </nb-action>
    <!-- <button [nbContextMenu]="userMenu"></button> -->
    <!-- <nb-layout>
      <nb-layout-header>
        <button outline nbButton [nbContextMenu]="userMenu">
          Show Menu
        </button>
      </nb-layout-header>
      <nb-layout-column class="colored-column-basic">Hello World!</nb-layout-column>
    </nb-layout> -->
  </nb-actions>
  <!-- <button  [nbContextMenu]="userMenu"  (onMenuItemClick)="onMenuItemClick($event)">asasd</button> -->
</div>