import { Injectable } from "@angular/core";
import { Router, CanLoad } from "@angular/router";
import { Observable } from "rxjs";
import { filter, map, take } from "rxjs/operators";
import { AdminService } from "../services/admin.service";

@Injectable({
  providedIn: "root",
})
export class AuthGuard implements CanLoad {
  constructor(private adminService: AdminService, private router: Router) {}
  canLoad(): Observable<boolean> {
    return this.adminService.isAuthenticated.pipe(
      filter((val) => val !== null),
      take(1),
      map((isAuth) => {
        if (isAuth) {
          return true;
        } else {
          return false;
        }
      })
    );
  }
}
