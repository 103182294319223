import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "areaFilter",
})
export class AreaFilterPipe implements PipeTransform {
  transform(areaList: any[], areafilter: string): any {
    if (!areaList || !areafilter) {
      return areaList;
    }

    return areaList.filter(
      (item) => item.Area.toLowerCase().indexOf(areafilter.toLowerCase()) !== -1
    );
  }
}
