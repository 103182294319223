import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-ngx-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss']
})
export class LoaderComponent implements OnInit {
  show = false;
  fullScreen = true;
  template:``;
  
  constructor() { 
  }

  ngOnInit(): void {
  }

}
